/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components'
import PageSection from 'components/PageSection'
import { useWeb3React } from '@web3-react/core'
import useTheme from 'hooks/useTheme'
import { PageMeta } from 'components/Layout/Page'
import { useSattWallet } from 'state/satt/hooks'
import { StyledSocialLinks } from '@pancakeswap/uikit/src/components/Footer/styles'
import { Button, Flex, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useCallback, useMemo, useState } from 'react'
import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber'

// import WinSection from './components/WinSection'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { DeserializedPool, DeserializedPoolVault, DeserializedFarm } from 'state/types'
import { getCakeVaultEarnings } from 'views/Pools/helpers'
import { formatUnits } from '@ethersproject/units'
import { usePoolsPageFetch, usePoolsWithVault } from 'state/pools/hooks'
import { latinise } from 'utils/latinise'
import PoolCard from 'views/Pools/components/PoolCard'
import FlexLayout from 'components/Layout/Flex'
import { useUserPoolStakedOnly } from 'state/user/hooks'
import orderBy from 'lodash/orderBy'
import { FarmWithStakedValue } from 'views/Farms/components/types'
import { useRouter } from 'next/router'
import BigNumber from 'bignumber.js'
import { getFarmApr } from 'utils/apr'
import { ChainId } from '@moonbiz/sdk'
import { useFarms, usePollFarmsWithUserData } from 'state/farms/hooks'
import CakeVaultCard from 'views/Pools/components/CakeVaultCard'
import MetricsSection from './components/MetricsSection'
import Hero from './components/Hero'

const StyledHeroSection = styled(PageSection)`
  padding-top: 16px;
  margin: 0;
  width: 100%;
  background-image: url('images/home/MOON10C1.png');

  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;

  ${({ theme }) => theme.mediaQueries.xxl} {
    padding-top: 48px;
  }
`
const CardLayout = styled(FlexLayout)`
  justify-content: center;
  font-family: 'Work Sans';
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ActiveButton = styled(Button)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: x-large;
  padding: 22px 15px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #fc3d21;
  background-color: unset;
  border-radius: 0;
  width: 282px;
  border-bottom: 5px solid #fc3d21;
  box-shadow: none;
  max-width: 30vw;
  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 10vw;
  }
`

const InactiveButton = styled(Button)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: x-large;
  padding: 22px 15px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ceccda;
  background-color: unset;
  border-radius: 0;
  border-bottom: 1px solid #ceccda;
  box-shadow: none;
  max-width: 30vw;
  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 10vw;
  }
`

const ActiveDarkButton = styled(Button)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  //width: 282px;
  font-size: x-large;
  padding: 22px 15px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #c9dcfb;
  background-color: unset;
  border-radius: 0;
  border-bottom: 5px solid #c9dcfb;
  box-shadow: none;
  max-width: 30vw;
  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 10vw;
  }
`

const InactiveDarkButton = styled(Button)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: x-large;
  padding: 22px 15px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #9f9dad;
  background-color: unset;
  border-radius: 0;
  border-bottom: 1px solid #9f9dad;
  box-shadow: none;
  max-width: 30vw;
  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 10vw;
  }
`
const FarmText = styled(Text)`
  font-family: 'Open Sans';
  font-style: normal;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
line-height: 20px;
  line-height: 100%;
  position: absolute;
  top: 170px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.02em;
  ${({ theme }) => theme.mediaQueries.md} {
    font-weight: 700;
    font-size: 28.43px;
    line-height: 100%;
    position: absolute;
    align-items: flex-end;
    text-align: center;
    letter-spacing: 0.02em;
  };
}
  
  
`
const FarmText2 = styled(Text)`
  font-family: 'Open Sans';
  font-style: normal;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  position: absolute;
  top: 205px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.02em;
  color: #7d9cd1;
  ${({ theme }) => theme.mediaQueries.md} {
    font-weight: 700;
    font-size: 28.43px;
    line-height: 100%;
    position: absolute;

    align-items: flex-end;
    text-align: center;
    letter-spacing: 0.02em;
  };
}
  
`
const PoolText = styled(Text)`
font-weight: 700;
font-size: 20px;
line-height: 20px;
position: absolute;
top: 170px;
align-items: flex-end;
text-align: center;
letter-spacing: 0.02em;
  font-family: 'Open Sans';
  font-style: normal;
  align-items: center;
  text-align: center;
 
  ${({ theme }) => theme.mediaQueries.md} {
    font-weight: 700;
    font-size: 28.43px;
    line-height: 100%;
    position: absolute;
    top: 170px;
    align-items: flex-end;
    text-align: center;
    letter-spacing: 0.02em;
  };
}
  
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PoolText2 = styled(Text)`
  font-family: 'Open Sans';
  font-style: normal;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-size: 28.43px;
  line-height: 100%;
  position: absolute;
  top: 198px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.02em;
`
const NUMBER_OF_POOLS_VISIBLE = 3
const NUMBER_OF_FARMS_VISIBLE = 3

const Home: React.FC = () => {
  const { theme } = useTheme()

  const [type, setType] = useState('pools')

  const [numberOfPoolsVisible] = useState(NUMBER_OF_POOLS_VISIBLE)
  const [searchQuery] = useState('')
  const { pathname } = useRouter()

  const sortPools = (
    account: string,
    sortOption: string,
    pools: DeserializedPool[],
    poolsToSort: DeserializedPool[],
  ) => {
    switch (sortOption) {
      case 'apr':
        // Ternary is needed to prevent pools without APR (like MIX) getting top spot
        return orderBy(poolsToSort, (pool: DeserializedPool) => (pool.apr ? pool.apr : 0), 'desc')
      case 'earned':
        return orderBy(
          poolsToSort,
          (pool: DeserializedPool) => {
            if (!pool.userData || !pool.earningTokenPrice) {
              return 0
            }

            if (pool.vaultKey) {
              const vault = pool as DeserializedPoolVault
              if (!vault.userData || !vault.userData.userShares) {
                return 0
              }
              return getCakeVaultEarnings(
                account,
                vault.userData.cakeAtLastUserAction,
                vault.userData.userShares,
                vault.pricePerFullShare,
                vault.earningTokenPrice,
                vault.userData.currentOverdueFee.plus(vault.userData.currentPerformanceFee),
              ).autoUsdToDisplay
            }
            return pool.userData.pendingReward.times(pool.earningTokenPrice).toNumber()
          },
          'desc',
        )
      case 'totalStaked': {
        return orderBy(
          poolsToSort,
          (pool: DeserializedPool) => {
            let totalStaked = Number.NaN
            if (pool.vaultKey) {
              const vault = pool as DeserializedPoolVault
              if (pool.stakingTokenPrice && vault.totalCakeInVault.isFinite()) {
                totalStaked =
                  +formatUnits(EthersBigNumber.from(vault.totalCakeInVault.toString()), pool.stakingToken.decimals) *
                  pool.stakingTokenPrice.toNumber()
              }
            } else if (pool.totalStaked?.isFinite() && pool.stakingTokenPrice) {
              totalStaked =
                +formatUnits(EthersBigNumber.from(pool.totalStaked.toString()), pool.stakingToken.decimals) *
                pool.stakingTokenPrice.toNumber()
            }
            return Number.isFinite(totalStaked) ? totalStaked : 0
          },
          'desc',
        )
      }
      case 'latest':
        return orderBy(poolsToSort, (pool: DeserializedPool) => Number(pool.sousId), 'desc')
      default:
        return poolsToSort
    }
  }
  const { account } = useWeb3React()
  const { sattAddress } = useSattWallet()
  const address = account || sattAddress || undefined

  const [sortOption] = useState('hot')
  const { pools }: any = usePoolsWithVault()
  const isArchived = pathname.includes('archived')
  const isInactive = pathname.includes('history')
  const isActive = !isInactive && !isArchived
  const cakePrice = BigNumber(localStorage.getItem('price'))
  const [query] = useState('')
  usePoolsPageFetch()
  usePollFarmsWithUserData()

  const pools2 = useSelector((state: AppState) => state.pools)

  let chosenPools = pools2.data
  const [numberOfFarmsVisible] = useState(NUMBER_OF_FARMS_VISIBLE)

  chosenPools = useMemo(() => {
    const sortedPools = sortPools(address, sortOption, pools, chosenPools).slice(0, numberOfPoolsVisible)

    if (searchQuery) {
      const lowercaseQuery = latinise(searchQuery.toLowerCase())
      return sortedPools.filter((pool) => latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery))
    }

    return sortedPools
  }, [address, sortOption, pools, chosenPools, numberOfPoolsVisible, searchQuery])
  const [stakedOnly] = useUserPoolStakedOnly()
  const { data: farmsLP, poolLength, regularCakePerBlock } = useFarms()

  const archivedFarms = farmsLP

  const stakedArchivedFarms = archivedFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const inactiveFarms = farmsLP.filter(
    (farm) => farm.pid !== 0 && farm.pid !== 1 && farm.pid !== 2 && farm.multiplier === '0X',
  )

  const stakedInactiveFarms = inactiveFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const activeFarms = farmsLP.filter(
    (farm) =>
      farm.pid !== 0 &&
      farm.pid !== 1 &&
      farm.pid !== 2 &&
      farm.multiplier !== '0X' &&
      (!poolLength || poolLength > farm.pid),
  )

  const stakedOnlyFarms = activeFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const farmsList = useCallback(
    (farmsToDisplay: DeserializedFarm[]): FarmWithStakedValue[] => {
      let farmsToDisplayWithAPR: FarmWithStakedValue[] = farmsToDisplay.map((farm) => {
        if (!farm.lpTotalInQuoteToken || !farm.quoteTokenPriceBusd) {
          return farm
        }
        const totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(farm.quoteTokenPriceBusd)
        const { cakeRewardsApr, lpRewardsApr } = isActive
          ? getFarmApr(
              new BigNumber(farm.poolWeight),
              cakePrice,
              totalLiquidity,
              farm.lpAddresses[ChainId.MAINNET],
              regularCakePerBlock,
            )
          : { cakeRewardsApr: 0, lpRewardsApr: 0 }

        return { ...farm, apr: cakeRewardsApr, lpRewardsApr, liquidity: totalLiquidity }
      })

      if (query) {
        const lowercaseQuery = latinise(query.toLowerCase())
        farmsToDisplayWithAPR = farmsToDisplayWithAPR.filter((farm: FarmWithStakedValue) => {
          return latinise(farm.lpSymbol.toLowerCase()).includes(lowercaseQuery)
        })
      }
      return farmsToDisplayWithAPR
    },
    [cakePrice, query, isActive, regularCakePerBlock],
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const chosenFarmsMemoized = useMemo(() => {
    let chosenFarms = []

    const sortFarms = (farms: FarmWithStakedValue[]): FarmWithStakedValue[] => {
      switch (sortOption) {
        case 'apr':
          return orderBy(farms, (farm: FarmWithStakedValue) => farm.apr + farm.lpRewardsApr, 'desc')
        case 'multiplier':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) => (farm.multiplier ? Number(farm.multiplier.slice(0, -1)) : 0),
            'desc',
          )
        case 'earned':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) => (farm.userData ? Number(farm.userData.earnings) : 0),
            'desc',
          )
        case 'liquidity':
          return orderBy(farms, (farm: FarmWithStakedValue) => Number(farm.liquidity), 'desc')
        case 'latest':
          return orderBy(farms, (farm: FarmWithStakedValue) => Number(farm.pid), 'desc')
        default:
          return farms
      }
    }

    if (isActive) {
      chosenFarms = stakedOnly ? farmsList(stakedOnlyFarms) : farmsList(activeFarms)
    }
    if (isInactive) {
      chosenFarms = stakedOnly ? farmsList(stakedInactiveFarms) : farmsList(inactiveFarms)
    }
    if (isArchived) {
      chosenFarms = stakedOnly ? farmsList(stakedArchivedFarms) : farmsList(archivedFarms)
    }

    return sortFarms(chosenFarms)
  }, [
    sortOption,
    activeFarms,
    farmsList,
    inactiveFarms,
    archivedFarms,
    isActive,
    isInactive,
    isArchived,
    stakedArchivedFarms,
    stakedInactiveFarms,
    stakedOnly,
    stakedOnlyFarms,
    numberOfFarmsVisible,
  ])
  const { isMobile, isMd } = useMatchBreakpoints()

  return (
    <div style={{ marginTop: '-60px' }}>
      <PageMeta />
      <StyledHeroSection index={2} hasCurvedDivider={false}>
        {/* {(account || sattAddress) && (
          <UserBannerWrapper>
            <UserBanner />
          </UserBannerWrapper>
        )} */}
        {/* <MultipleBanner /> */}
        <Hero />
        {!isMd && !isMobile && !theme.isDark && (
          <StyledSocialLinks
            display="flex"
            flexDirection="column"
            position="absolute"
            top="100px"
            left="0"
            width="3.95 vw  !important"
            background="#FFFFFF;"
            border="1px solid #093174;"
            borderRadius="0px 15px 15px 0px;"
            padding="10px 0px 10px 17px"
          />
        )}
        {!isMd && !isMobile && theme.isDark && (
          <StyledSocialLinks
            display="flex"
            flexDirection="column"
            position="absolute"
            top="100px"
            left="0"
            width="3.95 vw  !important"
            background="rgba(11, 61, 145, 0.2)"
            border="1px solid #093174;"
            borderRadius="0px 15px 15px 0px;"
            padding="10px 0px 10px 17px"
          />
        )}
      </StyledHeroSection>

      <Flex justifyContent="center">
        <MetricsSection />
      </Flex>
      <PageSection index={2} hasCurvedDivider={false}>
        {!theme.isDark &&
          (type === 'pools' ? (
            <Flex justifyContent="center" mb={6}>
              <InactiveButton onClick={() => setType('pools')}>Pools</InactiveButton>

              <InactiveButton onClick={() => setType('farms')}>Farms</InactiveButton>

              <PoolText>
                {' '}
                Juste stake some tokens to earn.
                <br />
                Hig APR, low risk.
              </PoolText>
            </Flex>
          ) : (
            <Flex justifyContent="center" mb={6}>
              <InactiveButton onClick={() => setType('pools')}>Pools</InactiveButton>

              <InactiveButton onClick={() => setType('farms')}>Farms</InactiveButton>
              <FarmText> Stake LP tokens to earn</FarmText>
              <FarmText2> community Auctions</FarmText2>
            </Flex>
          ))}

        {theme.isDark &&
          (type === 'pools' ? (
            <Flex justifyContent="center" mb={6}>
              <ActiveDarkButton onClick={() => setType('pools')}>Pools</ActiveDarkButton>

              <InactiveDarkButton onClick={() => setType('farms')}>Farms</InactiveDarkButton>
              <PoolText>
                {' '}
                Juste stake some tokens to earn.
                <br />
                Hig APR, low risk.
              </PoolText>
            </Flex>
          ) : (
            <Flex justifyContent="center" mb={6}>
              <InactiveDarkButton onClick={() => setType('pools')}>Pools</InactiveDarkButton>

              <ActiveDarkButton onClick={() => setType('farms')}>Farms</ActiveDarkButton>
              <FarmText> Stake LP tokens to earn</FarmText>
              <FarmText2> community Auctions</FarmText2>
            </Flex>
          ))}

        <Flex justifyContent="center">
          {type === 'pools' ? (
            <CardLayout>
              <div hidden>{/* <PoolsTable pools={chosenPools} account={address} userDataLoaded={pools.user} /> */}</div>

              {chosenPools?.map((pool) =>
                pool.vaultKey ? (
                  <CakeVaultCard key={pool.vaultKey} pool={pool} showStakedOnly={stakedOnly} />
                ) : (
                  <PoolCard key={pool.sousId} pool={pool} account={address} />
                ),
              )}
            </CardLayout>
          ) : (
            // <CardLayout>
            //   {chosenFarmsMemoized.map((farm) => (
            //     <FarmCard
            //       key={farm.pid}
            //       farm={farm}
            //       displayApr={getDisplayApr(farm.apr, farm.lpRewardsApr)}
            //       cakePrice={cakePrice}
            //       account={account || sattAddress}
            //       removed={false}
            //     />
            //   ))}
            // </CardLayout>
            <></>
          )}
        </Flex>
        {/* <OuterWedgeWrapper>
          <InnerWedgeWrapper top fill={theme.isDark ? '#201335' : '#D8CBED'}>
            <WedgeTopRight />
          </InnerWedgeWrapper>
        </OuterWedgeWrapper> */}
      </PageSection>

      {/* <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={theme.colors.gradients.cardHeader}
        index={2}
        hasCurvedDivider={false}
      >
        <OuterWedgeWrapper>
          <InnerWedgeWrapper width="150%" top fill={theme.colors.background}>
            <WedgeTopRight />
          </InnerWedgeWrapper>
        </OuterWedgeWrapper>
        <SalesSection {...earnSectionData(t)} />
        <FarmsPoolsRow />
      </PageSection> */}
    </div>
  )
}

export default Home
