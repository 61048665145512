import { Button, Flex, Heading, Text } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useTranslation } from 'contexts/Localization'

import styled from 'styled-components'

// const flyingAnim = () => keyframes`
//   from {
//     transform: translate(0,  0px);
//   }
//   50% {
//     transform: translate(-5px, -5px);
//   }
//   to {
//     transform: translate(0, 0px);
//   }
// `

// const fading = () => keyframes`
//   from {
//     opacity: 0.9;
//   }
//   50% {
//     opacity: 0.1;
//   }
//   to {
//     opacity: 0.9;
//   }
// `

// const BgWrapper = styled.div`
//   z-index: -1;
//   overflow: hidden;
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   bottom: 0px;
//   left: 0px;
// `

// const InnerWrapper = styled.div`
//   position: absolute;
//   width: 100%;
//   bottom: -3px;
// `
const ButtonSwap = styled(Button)`
  background: radial-gradient(120.83% 120.83% at 50% -7.29%, #fc3d21 13.54%, #931300 100%);
  color: #ffffff;
`
// const BunnyWrapper = styled.div`
//  width: 100%;
//  animation: ${flyingAnim} 3.5s ease-in-out infinite;
//  will-change: transform;
//  > span {
//    overflow: visible !important; //make sure the next-image pre-build blur image not be cropped
//  }
// `

// const StarsWrapper = styled.div`
//  position: absolute;
//  top: 0;
//  left: 0;

//  & :nth-child(2) {
//    animation: ${fading} 2s ease-in-out infinite;
//    animation-delay: 1s;
//  }

//  & :nth-child(3) {
//    animation: ${fading} 5s ease-in-out infinite;
//    animation-delay: 0.66s;
//  }

//  & :nth-child(4) {
//    animation: ${fading} 2.5s ease-in-out infinite;
//    animation-delay: 0.33s;
//  }
// `

// const starsImage: CompositeImageProps = {
//   path: '/images/home/lunar-bunny/',
// }

const Hero = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  return (
    <>
      {/* <BgWrapper>
        <InnerWrapper>{theme.isDark ? <SlideSvgDark width="100%" /> : <SlideSvgLight width="100%" />}</InnerWrapper>
      </BgWrapper> */}
      <Flex
        position="relative"
        flexDirection={['column-reverse', null, null, 'row']}
        alignItems={['flex-end', null, null, 'center']}
        justifyContent="center"
        mt={[account ? '280px' : '50px', null, 0]}
        id="homepage-hero"
      >
        <Flex flex="1" flexDirection="column" alignItems="center" style={{ textAlign: 'center', padding: '0 6%' }}>
          <Heading
            scale="xxl"
            color="#E9E9E9"
            mb="24px"
            fontFamily="Open Sans"
            fontStyle="normal"
            fontSize="80px!important"
            fontWeight="800"
            letterSpacing="0.01em"
          >
            <h2> {t('Welcome To the Moon!')}</h2>
          </Heading>

          <Heading
            scale="xxl"
            color="#E9E9E9"
            mb="24px"
            fontFamily="Open Sans"
            fontStyle="normal"
            fontSize="36px !important"
            fontWeight="700"
            letterSpacing="0.03em"
          >
            <h2> {t('SWAP, FARM & SPEND RP1')}</h2>
          </Heading>
          <Text
            mb="24px"
            color="#E9E9E9"
            fontFamily="Work Sans"
            fontStyle="normal"
            fontSize="18px !important"
            fontWeight="700"
          >
            {t('The Rocket Propelant One ak RP1 is the fuel for your trip to the moon.')}
            <br />
            <Text
              color="#E9E9E9"
              fontFamily="Work Sans"
              fontStyle="normal"
              fontSize="18px !important"
              fontWeight="700"
              textAlign="center"
            >
              {t('Prepare yourself farm RP1 now!')}
            </Text>
            <br />
          </Text>
          <br />
          <Flex>
            <NextLinkFromReactRouter to="/swap">
              <ButtonSwap>{t('Swap now')}</ButtonSwap>
            </NextLinkFromReactRouter>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default Hero
